
export default {
	name: "CardSwitch",
	model: {
		prop: "model_data",
		event: "change",
	},
	props: {
		model_data: {},
		label: {
			type: [String, Object],
			default: "",
		},
		customClass: {
			type: String,
			default: "",
		},
		color: {
			type: String,
			default: "success",
		},
		inset: {
			type: Boolean,
			default: true,
		},
		true_value: {
			type: [Boolean, String, Number],
			default: true,
		},
		false_value: {
			type: [Boolean, String, Number],
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		labelColor: {
			type: String,
			default: "black--text",
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		dataCy: {
			type: String,
			default: ""
		},
		align: {
			type: String,
			default: "start"
		},
		showLabel: {
			type: Boolean,
			default: true,
		},
	},
	data: function () {
		return {};
	},
	mounted() {},
	computed: {
		modelData: {
			set(val: any) {
				this.$emit("change", val);
			},
			get() {
				return this.model_data;
			},
		},
	},
	methods: {
		handlerClick(event: any) {
			const value = !this.modelData;
			this.$emit("click", { event, value });
		},
	},
	watch: {},
};
